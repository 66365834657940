@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap");
@layer base {
  body {
    @apply font-[Raleway];
  }
  li {
    @apply px-4;
    @apply cursor-pointer;
  }
}
.content-div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 250px;
}

/* Base styles for mobile-first approach */
.container {
  padding: 20px;
}

.navbar,
.footer {
  padding: 10px;
}

.main-content,
.sidebar {
  padding: 15px;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .container {
    padding: 40px;
  }

  .navbar,
  .footer {
    padding: 20px;
  }

  .main-content,
  .sidebar {
    padding: 25px;
  }
}

/* Large devices (desktops, 1024px and up) */
@media (min-width: 1024px) {
  .container {
    padding: 50px;
  }

  .navbar,
  .footer {
    padding: 30px;
  }

  .main-content {
    padding: 30px;
  }

  .sidebar {
    padding: 30px;
    flex: 0 0 250px; /* Fixed sidebar width */
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .container {
    max-width: 1140px; /* Max container width */
    margin: auto; /* Center container */
  }

  .navbar,
  .footer {
    padding: 40px;
  }

  .main-content {
    padding: 40px;
  }

  .sidebar {
    padding: 40px;
    flex: 0 0 300px; /* Increased sidebar width */
  }
}

/* XXL devices (extra large desktops, 1400px and up) */
@media (min-width: 1400px) {
  .container {
    max-width: 1320px; /* Increased max container width */
  }

  /* Adjustments for larger screens can be added here */
}
